import * as React from "react";
import Hero from "../components/hero";
import Block from "../components/block";
import Layout from "../components/layout";
import styled from "styled-components";
import SplitFlexBox from "../components/split-flex-box";
import { BREAKPOINTS, GREYBOX_COLOR } from "../consts";
import Footer from "../components/footer";
import { StaticImage } from "gatsby-plugin-image";
import UnderLinedLink from "../components/underlined-link";
import LargeButton from "../components/large-button";
import StatementParagraph from "../components/statement-paragraph";
import { graphql, Link } from "gatsby";
import AnimatedEntry from "../components/animated-entry";
import { useMediaQuery } from "react-responsive";

const Page = styled.div``;

const TrustedByIconGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const TrustedByIconContainer = styled.div`
  width: 33%;
  height: 4rem;
  margin-bottom: 4rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 6%;
  img {
    object-fit: contain !important;
  }
  &:last-child {
    margin-bottom: 8rem;
  }

  @media screen and (max-width: ${BREAKPOINTS["mobile"]}px) {
    margin-bottom: 1rem;
  }
`;
const CompanyEthosButtonContainer = styled.div`
  text-align: ${(props) => (props.isMobile ? `center` : `right`)};
  justify-content: ${(props) => (props.isMobile ? `center` : `flex-end`)};
  display: flex;
`;
const CompanyEthosContainer = styled(SplitFlexBox)`
  align-items: center;
  h2 {
    text-align: ${(props) => (props.isMobile ? "center" : "left")};
    margin-bottom: ${(props) => (props.isMobile ? "1.2rem" : "0")};
  }
`;

const BifrostHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.6rem;
  svg {
    margin-right: 1rem;
  }
  span {
    font-size: 1.1rem;
    position: relative;
    top: 3px;
  }
  p.h3 {
    line-height: 100%;
    margin-bottom: 0;
    font-weight: 500;
  }
`;
const BifrostAppLinks = styled.div`
  > a {
    margin-right: 0.6rem;
    > div {
    }
  }
`;

const IndexPage = ({ data }) => {
  const [isMobile, setIsMobile] = React.useState(false);
  const mediaQuery = useMediaQuery({
    query: `(max-width: ${BREAKPOINTS["mobile"]}px)`,
  });
  React.useEffect(() => {
    setIsMobile(mediaQuery);
  }, [mediaQuery, isMobile]);

  return (
    <Page className={isMobile ? "is-mobile" : ""}>
      <Hero
        text="Shaping the way you interact with the blockchain."
        imageData={isMobile ? data.mobileHeroImage : data.heroImage}
      />

      <Layout>
        <Block justifyContent={"right"} fullHeight={true}>
          <StatementParagraph>
            Build value with utility. Crypto solutions should be simple and
            secure, which is why we focus on creating purposeful products and
            tools which integrate with the blockchain.
          </StatementParagraph>
        </Block>

        <Block noBottomPadding={true}>
          <AnimatedEntry animation="line" />
        </Block>

        <Block>
          <SplitFlexBox>
            <h2>Trusted By</h2>
            <TrustedByIconGrid>
              <TrustedByIconContainer>
                <StaticImage
                  src="../images/partnerlogos/flare.png"
                  alt=""
                  width={160}
                  quality={90}
                  placeholder="none"
                />
              </TrustedByIconContainer>
              <TrustedByIconContainer>
                <StaticImage
                  src="../images/partnerlogos/xumm.png"
                  alt=""
                  width={160}
                  quality={90}
                  placeholder="none"
                />
              </TrustedByIconContainer>
              <TrustedByIconContainer>
                <StaticImage
                  src="../images/partnerlogos/ledger.png"
                  alt=""
                  width={160}
                  quality={90}
                  placeholder="none"
                />
              </TrustedByIconContainer>
              <TrustedByIconContainer>
                <StaticImage
                  src="../images/partnerlogos/dcent.png"
                  alt=""
                  width={160}
                  quality={90}
                  placeholder="none"
                />
              </TrustedByIconContainer>
              <TrustedByIconContainer>
                <StaticImage
                  src="../images/partnerlogos/gala.png"
                  alt=""
                  width={160}
                  quality={90}
                  placeholder="none"
                />
              </TrustedByIconContainer>
              <TrustedByIconContainer>
                <StaticImage
                  src="../images/partnerlogos/keystone.png"
                  alt=""
                  width={160}
                  quality={90}
                  placeholder="none"
                />
              </TrustedByIconContainer>
            </TrustedByIconGrid>
          </SplitFlexBox>
        </Block>

        <Block id="products" backgroundColor={GREYBOX_COLOR} fullHeight={true}>
          <SplitFlexBox floatInMiddle={true}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <BifrostHeader>
              <StaticImage
                  src="../images/BifrostWalletLogo.png"
                  alt=""
                  width={268}
                  quality={90}
                  placeholder="none"
                />
              </BifrostHeader>
              <h2
                style={
                  isMobile ? { marginBottom: "0" } : { marginBottom: "parent" }
                }
              >
                {isMobile ? (
                  <span>Self custody in the palm of your hand</span>
                ) : (
                  <span>
                    Custody in <br /> the palm of <br /> your hand
                  </span>
                )}
              </h2>

              {isMobile && (
                <div>
                  <StaticImage
                    src="../images/Wallet.png"
                    alt="Screenshot from the Bifrost Wallet Application"
                    placeholder="none"
                    quality={90}
                    layout="fullWidth"
                    style={{ marginBottom: "3rem", marginTop: "3rem" }}
                  />
                </div>
              )}

              <p>
                Have full control over your crypto assets with your personal
                wallet, NFT gallery, and web3 browser all in one secure mobile app.
              </p>

              <p>Your keys, your crypto.</p>

              <div className="p">
                <UnderLinedLink
                  to="http://bifrostwallet.com/"
                  isMobile={isMobile}
                >
                  bifrostwallet.com
                </UnderLinedLink>
              </div>

              <BifrostAppLinks
                style={
                  isMobile
                    ? {
                        marginBottom: "4rem",
                        display: "flex",
                        justifyContent: "space-between",
                      }
                    : {}
                }
              >
                <a href="https://apps.apple.com/us/app/bifrost-wallet/id1577198351">
                  <StaticImage
                    src="../images/Apple.png"
                    quality={90}
                    alt="Download on the App Store"
                    height={50}
                  />
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.bifrostwallet.app">
                  <StaticImage
                    src="../images/Google.png"
                    quality={90}
                    alt="Get it on Google Play"
                    height={50}
                  />
                </a>
              </BifrostAppLinks>
            </div>
            {!isMobile && (
              <div>
                <StaticImage
                  src="../images/Wallet.png"
                  alt="Screenshot from the Bifrost Wallet Application"
                  placeholder="none"
                  quality={90}
                  layout="fullWidth"
                />
              </div>
            )}
          </SplitFlexBox>
        </Block>

        <Block backgroundColor={"#272727"} textColor="white" fullHeight={true}>
          <SplitFlexBox floatInMiddle={true}>
            {!isMobile && (
              <div>
                <StaticImage
                  src="../images/BifrostOrb.png"
                  quality={90}
                  alt="Bifrost Delegation"
                  placeholder="none"
                  layout="fullWidth"
                />
              </div>
            )}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <h2>
                {isMobile ? (
                  <span>Delegation made easy</span>
                ) : (
                  <span>
                    Delegation <br /> made easy
                  </span>
                )}
              </h2>
              {isMobile && (
                <div>
                  <StaticImage
                    src="../images/BifrostOrb.png"
                    quality={90}
                    alt="Bifrost Delegation"
                    placeholder="none"
                    layout="fullWidth"
                    style={{ marginBottom: "0rem", marginTop: "0rem" }}
                  />
                </div>
              )}

              <p>
                Unlock the true value of your Flare (FLR) and Songbird (SGB) tokens by delegating
                your vote to Bifrost Oracle, risk-free.
              </p>

              <p>
                We provide accurate and reliable price data to the Flare Time Series Oracle, rewarding
                delegators in the native token.
              </p>

              <div className="p">
                <UnderLinedLink
                  color="white"
                  isMobile={isMobile}
                  to="http://bifrostoracle.com/"
                >
                  bifrostoracle.com
                </UnderLinedLink>
              </div>
            </div>
          </SplitFlexBox>
        </Block>

        <Block viewHeight={90}>
          <SplitFlexBox>
            <h2>Blockchain Infrastructure</h2>
            <div>
              <p>
                Towo Labs is committed to ensuring the long term health and
                stability of the blockchain ecosystem. We run <strong> servers </strong>
                that help secure and decentralize blockchains like the XRP Ledger, Songbird, Flare, Dogecoin and Bitcoin.
              </p>
              <p>
                We are also making sure that access to the blockchain is as easy and secure as possible, 
                by providing a number of<strong> nodes </strong>and<strong> faucets</strong> that developers use to
                read blockchain data and acquire test tokens.
              </p>

              <div className="p">
                <UnderLinedLink
                  rotation="0"
                  to="infrastructure/"
                  internal={true}
                  isMobile={isMobile}
                >
                  Learn more
                </UnderLinedLink>
              </div>
            </div>
          </SplitFlexBox>
        </Block>

        <Block backgroundColor={GREYBOX_COLOR}>
          <CompanyEthosContainer isMobile={isMobile}>
            <h2>
              {isMobile ? (
                <span>Our company ethos</span>
              ) : (
                <span>
                  Our company <br /> ethos
                </span>
              )}
            </h2>
            <CompanyEthosButtonContainer isMobile={isMobile}>
              <Link to="company/">
                <LargeButton>
                  Learn more
                  <div className="icon-container">
                    <StaticImage
                      src="../images/Arrow.png"
                      alt=""
                      width={28}
                      quality={90}
                      placeholder="none"
                      className="icon main-icon"
                      style={{
                        marginLeft: "1rem",
                      }}
                    />
                    <StaticImage
                      src="../images/Arrow.png"
                      alt=""
                      width={28}
                      quality={90}
                      placeholder="none"
                      className="icon hover-icon"
                      style={{
                        marginLeft: "1rem",
                        filter: "brightness(0) invert(1)",
                      }}
                    />
                  </div>
                </LargeButton>
              </Link>
            </CompanyEthosButtonContainer>
          </CompanyEthosContainer>
        </Block>

        <Footer />
      </Layout>
    </Page>
  );
};

export const query = graphql`
  query IndexPageQuery {
    heroImage: file(name: { eq: "home" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    mobileHeroImage: file(name: { eq: "home-mobile" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`;

export default IndexPage;
