import styled from "styled-components";
import { BREAKPOINTS } from "../consts";

export default styled.div`
  margin: 0;
  padding: 0;
  font-weight: 300;
  max-width: 70%;
  font-size: 2.2rem;
  line-height: 2.6rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  @media screen and (max-width: ${BREAKPOINTS["mobile"]}px) {
    max-width: 100%;
    font-size: 1.6rem;
    line-height: 2.2rem;
  }
`;
