import styled from "styled-components";
import { BREAKPOINTS } from "../consts";

export default styled.button`
  border: 2px solid #080808;
  border-radius: 100px;
  font-size: ${(props) =>
  props.fontSize ? props.fontSize : `1.3rem`};
  padding: 1rem 2.8rem;
  background: none;
  cursor: pointer;
  color: black;
  display: flex;
  align-items: center;
  transition: all 0.2s ease-in-out 0s;
  .icon-container {
    position: relative;
    display: inline-flex;
  }
  .main-icon {
    display: inline-block;
    opacity: 1;
  }
  .hover-icon {
      display: inline-block;
      opacity: 0;
      position: absolute;
      top: 0px;
      left: 0px;
      transition: opacity 0.2s ease-in-out 0s;
    }
  }
  &:hover {
    background-color: #080808;
    color: white;
    a {
      color: white;
    }
    .main-icon {
      opacity: 0;
    }
    .hover-icon {
      opacity: 1;
    }
  }

  @media screen and (max-width: ${BREAKPOINTS["mobile"]}px) {
    padding: 1rem 1.8rem;
  }
`;
